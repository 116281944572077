.b-summary_table {
	font-weight: 500;
	width: 100%;

	&-name,
	&-value {
		font-weight: inherit;
		padding: 5px 0;
		text-align: start;
	}

	&-name {
		padding-right: 14px;

		&.m-tooltip {
			display: flex;
		}
	}

	&-value {
		text-align: end;
		vertical-align: top;
		white-space: nowrap;

		&.m-free_shipping {
			color: $color-accent;
			text-transform: uppercase;
		}

		&.m-top_align {
			vertical-align: top;
		}
	}

	&-item {
		display: table;
		width: 100%;

		&.m-discount {
			color: $color-accent;
		}

		&.m-total {
			border-top: 1px solid $color-divider-light;
			font-size: 16px;
			font-weight: 700;
			margin: 14px 0 0;

			.b-summary_table-name,
			.b-summary_table-value {
				padding: 19px 0 15px;
			}
		}
	}

	&-tax {
		color: $color-grey-50;
		font-size: 12px;
		font-weight: 400;
	}
}
