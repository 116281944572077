.b-order_details {
	$root: &;

	@include media(xl) {
		display: flex;
	}

	&.m-cancel,
	&.m-guest {
		@include g-section_holder;

		margin: 24px auto;
	}

	&-top {
		padding-bottom: 40px;
		width: 100%;
	}

	&-caption {
		font-size: 44px;
		font-weight: 700;
		line-height: 1.2;
		padding-bottom: 12px;
	}

	&-main {
		margin-bottom: 56px;

		@include media(xl) {
			margin-bottom: 0;
			padding-inline-end: 12%;
			width: 65%;
		}

		#{$root}.m-cancel & {
			@include media(xl) {
				padding-inline-end: 0;
				width: 100%;
			}
		}
	}

	&-asset {
		border-top: 1px solid $color-divider-light;
		margin-bottom: 40px;
		padding-top: 40px;
	}

	&-footer {
		border-top: 1px solid $color-divider-light;
		display: flex;
		margin-bottom: 20px;
		padding-top: 20px;

		@include media(sm) {
			flex-direction: column;
		}
	}

	&-aside {
		@include media(xl) {
			width: 35%;
		}

		.b-order_details.m-guest & {
			width: 100%;

			@include media(md) {
				width: 42%;
			}

			@include media(lg-up) {
				max-width: 300px;
			}
		}

		&.m-checkout {
			width: 100%;
		}
	}

	&-aside_item {
		margin-bottom: 48px;

		&:first-child {
			margin-bottom: 28px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-title {
		@include g-heading_4;

		margin-bottom: 32px;
		width: 100%;
	}

	&-subtitle {
		font-weight: 600;
		margin-bottom: 12px;
	}

	&-counter {
		margin-bottom: 10px;
	}

	&-cancellation_text {
		& + & {
			margin-top: 10px;
		}

		&.m-accent {
			font-weight: 700;
		}
	}

	&-cancellation_body {
		border-color: $color-divider-light;
		border-style: solid;
		border-width: 1px 0;
		padding-top: 20px;
	}

	&-cancellation_footer {
		margin-top: 20px;
	}
}
