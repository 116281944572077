.b-payment_disclaimer {
	background-color: $color-warning-obj;
	border: 1px solid $color-grey-25;
	border-radius: 2px;
	color: $color-text;
	display: flex;
	margin-bottom: 20px;
	padding: 6px 10px;

	&-icon {
		border: 1px solid;
		border-radius: 50%;
		flex: 0 0 18px;
		height: 18px;
		line-height: 1;
		text-align: center;
	}

	&-text {
		padding-left: 4px;
	}
}
