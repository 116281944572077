/*md

# Form

Form component designed to create an easy and versatile form layout system.

Forms are built with a combination of standard form elements with a `.b-form_section` wrapper to hold labels, errors and caption for each field.\

Each line can hold from one to three form elements, it required only modifier to control that. Also it possible to use any form element inside lines

```html_example
<form class="b-form" autocomplete="on" novalidate="" data-widget="shippingForm" data-event-submit.prevent="handleSubmit" data-widget-event-updateshippingform="updateSummaryEv" data-address-selector="shippingAddressSelector" data-address-fields="dwfrm_shipping_shippingAddress_addressFields_firstName,dwfrm_shipping_shippingAddress_addressFields_lastName,dwfrm_shipping_shippingAddress_addressFields_address1,dwfrm_shipping_shippingAddress_addressFields_address2,dwfrm_shipping_shippingAddress_addressFields_country,dwfrm_shipping_shippingAddress_addressFields_states_stateCode,dwfrm_shipping_shippingAddress_addressFields_city,dwfrm_shipping_shippingAddress_addressFields_postalCode,dwfrm_shipping_shippingAddress_addressFields_phone" data-widget-event-submit="shippingSubmitted" data-select-shipping-method-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-SelectShippingMethod" data-update-shipping-list-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-UpdateShippingMethodsList" data-create-shipment-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutAddressServices-CreateNewAddress" action="/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-SubmitShipping" data-address-mode="edit" data-form-definition-url="/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/Forms-FormDefinition?form=shipping" name="dwfrm_shipping" id="dwfrm_shipping" data-address-autocomplete-enabled="false" data-initialized="1" aria-busy="false">

	<div class="b-form-message" role="alert" data-ref="errorMessageLabel">Form error message here</div>

	<fieldset class="b-form-set" data-ref="addressForm">
		<legend class="b-form-set_label">
			Shipping address (form-set)
		</legend>

		<div class="b-form_section">
			<label class="b-form_section-label">
				First Name (form_section)
			</label>
			<input class="b-input" type="text" placeholder="First Name">
		</div>

		<div class="b-form-line m-equal">
			<div class="b-form_section">
				<label class="b-form_section-label">
					First Name (form-line equal)
				</label>
				<input class="b-input" type="text" placeholder="First Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Last Name (form-line)
				</label>
				<input class="b-input" type="text" placeholder="Last Name">
			</div>
		</div>

		<div class="b-form-line m-fixed_width">
			<div class="b-form_section">
				<label class="b-form_section-label">
					First Name (form-line fixed-width)
				</label>
				<input class="b-input" type="text" placeholder="First Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Last Name
				</label>
				<input class="b-input" type="text" placeholder="Last Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Phone Number
				</label>
				<input class="b-input" type="text" placeholder="Phone Number">
			</div>
		</div>
	</fieldset>
</form>
```

*/

.b-form {
	&-title {
		@include g-heading_3;

		margin-bottom: rh(8);
	}

	&-description {
		margin-bottom: rh(5);
		padding: rh(2 0);
	}

	&-message {
		background: $color-error-obj;
		border: 1px solid $color-error;
		color: $color-text;
		font-weight: 300;
		margin-bottom: rh(8);
		padding: rh(4);
		text-align: center;
	}

	&-error_feedback {
		color: $color-error;
		font-size: 12px;
		font-weight: 500;
		margin-top: rh(5);
	}

	// Set
	&-set {
		border: none;
		display: block;
		margin: rh(4 0);
		min-width: 0;
		padding: 0;
		position: relative;

		&[hidden] {
			display: none;
		}
	}

	&-set_label {
		@include g-heading_6;

		display: block;
		margin-bottom: rh(4);
		max-width: 100%;
		padding: 0;
		white-space: normal;
		width: 100%;

		&.m-wai {
			@include hide(visually);
		}
	}

	// Line
	&-line {
		display: flex;
		width: 100%;

		&.m-actions {
			margin-top: rh(12);
		}

		&[hidden] {
			@include hide(visually);
		}

		> .b-form_section {
			width: 100%;
		}

		&.m-equal {
			justify-content: space-between;

			.b-form_section {
				width: calc(50% - #{rh(2.5)});

				@include media(sm) {
					width: 83%;
				}
			}
		}

		&.m-fixed_width {
			.b-form_section {
				width: 150px;
			}
		}

		&.m-mobile_column {
			@include media(sm) {
				flex-flow: column;
			}
		}

		&.m-card_expiration {
			flex-wrap: nowrap;
		}
	}

	&-line_divider {
		align-items: center;
		display: flex;
		font-size: 16px;
		font-weight: 300;
		height: $size-input-height;
		margin: rh(0 4);
	}

	// Buttons
	&-btn_save {
		margin-inline-end: rh(5);

		@include media(sm) {
			margin: rh(0 0 5);
		}
	}

	&-btn_cancel {
		padding: rh(0 9);
	}

	// Text
	&-text {
		font-size: 14px;
		margin-bottom: 10px;

		&.m-top_margin {
			margin-top: 40px;
		}
	}
}

.b-form.m-account {
	@include media(md-up) {
		flex-grow: 0;
		max-width: 500px;
	}
}

.b-form.m-order_cancel {
	@include media(md-up) {
		flex-grow: 0;
		max-width: 580px;
	}

	.b-select {
		max-width: 375px;
	}
}
