.b-gift_certificate_balance {
	&-header {
		@include g-heading_2;

		margin-bottom: rh(10);
	}

	&-message {
		margin-bottom: rh(6);
	}

	&-value {
		font-weight: 700;
	}
}
