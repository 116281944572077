.l-account {
	@include g-section_holder;

	display: flex;
	flex-wrap: wrap;
	margin-bottom: 72px;

	@include media(lg-up) {
		margin-bottom: 88px;
	}

	&.m-small {
		max-width: 450px;
		padding-inline-end: grid-margin(sm);
		padding-inline-start: grid-margin(sm);
	}

	&.m-top_margin {
		margin-top: 40px;
	}

	&.m-password_message {
		margin-top: 24px;

		@include media(lg-up) {
			margin-top: 48px;
		}

		.b-account_banner + & {
			margin-top: -12px;

			@include media(lg-up) {
				margin-top: -60px;
			}
		}
	}

	&.m-gift_certificate_balance {
		margin-bottom: 88px;
		margin-top: 80px;
		max-width: 480px;
		padding-inline-end: grid-margin(sm);
		padding-inline-start: grid-margin(sm);

		@include media(sm) {
			margin-bottom: 56px;
			margin-top: 32px;
		}
	}

	&-message {
		margin-bottom: 24px;
		width: 100%;

		@include media(lg-up) {
			margin-bottom: 48px;
		}
	}

	&-inner {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		@include media(lg-up) {
			flex-wrap: nowrap;
		}
	}

	&-nav {
		overflow: hidden;
		padding: 0;
		width: 100%;

		@include media(lg) {
			flex-basis: grid-span(4, 'lg');
		}

		@include media(xl) {
			flex-basis: grid-span(3, 'xl');
		}
	}

	&-main {
		flex-basis: 100%;

		@include media(lg) {
			flex-basis: grid-span(8, 'lg');
			padding-inline-start: 32px;
		}

		@include media(xl) {
			flex-basis: grid-span(9, 'xl');
			padding-inline-start: 68px;
		}
	}

	&-carousel {
		width: 100%;
	}
}
