/*md

# Textarea

Default textarea element

## Default

```html_example
	<textarea
		id=""
		class="b-textarea m-valid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

## Invalid

```html_example
	<textarea
		id=""
		class="b-textarea m-invalid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

*/

.b-textarea {
	appearance: none;
	background: $color-bg;
	border: $global-line solid adjust-color-to-bg($color-bg, rgba($color-white, 0.3), rgba($color-black, 0.3));
	border-radius: $global-radius;
	box-shadow: none;
	color: adjust-color-to-bg($color-bg);
	cursor: text;
	font-family: inherit;
	font-size: 16px;
	max-width: 100%;
	min-height: 6em;
	min-width: 100%;
	padding: rh(2 4);
	vertical-align: baseline;

	&.m-invalid {
		background: $color-error-obj;
		border: 2px solid $color-error;
	}

	&.m-no_resize {
		resize: none;
	}

	&.m-disabled,
	&[disabled] {
		background: $color-bg-shade;
		cursor: default;
		pointer-events: none;
	}
}
