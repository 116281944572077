.b-login {
	display: flex;
	justify-content: space-between;
	margin: rh(10) auto;
	max-width: 900px;

	@include media(sm) {
		flex-direction: column;
	}

	&-section {
		padding-bottom: rh(10);

		@include media(md-up) {
			flex: 1 0 50%;
		}

		&:first-child {
			@include media(md-up) {
				border-right: 1px solid $color-divider;
				padding-right: rh(10);
			}
		}

		& + & {
			@include media(md-up) {
				padding-left: rh(10);
			}
		}
	}

	&-title {
		@include g-heading_3;

		margin-bottom: rh(5);
		text-align: center;
	}
}
