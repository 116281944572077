.b-user_greeting {
	align-items: center;
	display: flex;
	margin-bottom: rh(7);

	&-icon {
		align-items: flex-end;
		border: 2px solid $color-black;
		border-radius: 100%;
		margin-inline-end: rh(5);
		padding: 5px;
	}

	&-content {
		overflow: hidden;
	}

	&-message {
		font-size: 16px;
		font-weight: 700;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}

	&-signout {}
}
