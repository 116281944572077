.b-history_page {
	&-title {
		@include g-heading_1;

		padding-bottom: rh(3);
	}

	&-track_order {
		@include g-link;

		font-weight: 600;
		transition: color $motion-fast;
	}

	&-no_orders {
		margin-top: rh(10);
	}

	&-start_shopping {
		margin-top: rh(7);
	}

	&-find_order {
		margin-bottom: rh(8);
	}

	&-find_order_link {
		@include g-link(underlined);

		font-weight: 500;
	}

	&-filter {
		align-items: center;
		border-bottom: 1px solid $color-divider;
		display: flex;
		justify-content: flex-end;
		line-height: 1;
		margin-bottom: rh(5);
		padding-bottom: rh(5);

		@include media(md-up) {
			align-items: flex-end;
		}
	}

	&-total {
		flex-grow: 1;
		font-size: 16px;
		font-weight: 500;
	}

	&-select_wrap {
		align-items: center;
		display: flex;
	}

	&-select_label {
		color: $color-text-dimmed;
		font-size: 16px;
		margin-inline-end: rh(3);
	}

	&-select {
		max-width: 200px;

		@include media(sm) {
			max-width: 160px;
		}
	}
}
