.b-account_banner {
	@include g-section_holder;

	color: adjust-color-to-bg($color-bg);
	margin: rh(6) auto rh(6);

	@include media(lg-up) {
		margin: rh(12) auto rh(18);
	}

	&-inner {
		border-bottom: 1px solid $color-divider;
		border-top: 1px solid $color-divider;
		padding-bottom: rh(8);
		padding-top: rh(8);
		text-align: center;
	}

	&-link {
		@include g-link;

		display: inline-block;
		font-weight: 500;
		transition: color $motion-fast;
	}
}
