.b-summary_group {
	&-item {
		margin-top: 24px;
	}

	&-email {
		font-weight: 300;
		word-wrap: break-word;
	}

	&-billing {} // on confirmation page only

	&-title {
		font-weight: 600;
		margin-bottom: 12px;
	}

	&-caption {
		color: $color-text-dimmed; // used on confirmation page. Like: * item are listed under your items.
	}

	&-message {
		color: $color-success;
		font-weight: 500;
		margin-top: 20px;
	}
}

.b-summary_group.m-columns { // Used on checkout step summary, when it should be columned
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.b-summary_group-item {
		margin-top: 0;
		width: calc(50% - 12px);

		&.m-gift {
			margin-top: 24px;
			width: 100%;
			word-break: break-word;
		}
	}
}
