.b-summary_shipping {
	font-weight: 300;
	line-height: 1.6;

	&-method {}

	&-name {}

	&-arrival_time {
		color: $color-text-dimmed;
		display: block;
	}

	&-cost {
		font-weight: bold;
		margin-inline-start: auto;
	}
}
