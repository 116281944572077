.b-card {
	border-bottom: 2px solid $color-grey-25;
	border-radius: $global-radius;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	overflow: hidden;
	padding-bottom: 40px;

	&:last-child {
		border-bottom: none;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	&.m-account {
		border-bottom: 0;
		margin-bottom: 16px;
		padding-bottom: 0;
	}

	&-inner {
		display: flex;
		justify-content: space-between;

		@include media(sm) {
			flex-direction: column;
		}
	}

	&-inner_item {
		@include media(md-up) {
			width: grid-span(6, 'lg', true);
		}
	}

	&-header {
		margin: 20px 0 8px;
	}

	&-title {
		@include g-heading_3;

		margin-bottom: 0;
		text-align: start;
	}

	&-shipment {
		margin: 20px 0 10px;

		&.m-large {
			font-size: 20px;
		}
	}

	&-shipment_text {
		font-weight: 500;
	}

	&-body {
		padding: 8px 0 24px;

		& ~ & {
			border-top: 1px dashed $color-grey-25;
			margin-top: 20px;
		}

		table {
			width: 100%;
		}

		th,
		td {
			text-align: start;

			@include media(sm) {
				display: table-row;
			}
		}

		tr {
			@include media(sm) {
				display: table-row-group;
			}
		}

		th {
			padding-bottom: 4px;
			width: 125px;
		}
	}

	&-button {
		margin-bottom: 20px;

		@include media(sm) {
			padding-left: 6px;
			padding-right: 6px;
		}

		@include media(md-up) {
			margin-bottom: 0;
			margin-inline-end: 20px;
			padding-left: 15px;
			padding-right: 15px;
		}

		&:last-child {
			margin-bottom: 0;
			margin-inline-end: 0;
		}
	}

	&-list {
		font-weight: 300;
		line-height: 1.7;
	}

	&-list_link {
		@include g-link(underlined);
	}

	&-message {
		font-weight: 300;
		margin-bottom: 4px;
	}

	&-thumbnails {
		display: flex;
		margin-top: 20px;
	}

	&-thumbnails_item {
		margin-inline-end: 8px;
		max-width: 100%;
		width: 87px;

		@include media(md-up) {
			margin-inline-end: 20px;
		}

		@include media(lg) {
			max-width: 15.4%;
		}

		@include media(sm) {
			max-width: 51px;
			width: 16.67%;
		}

		&:last-child {
			margin-inline-end: 0;
		}

		&.m-additional {
			align-items: center;
			background-color: $color-grey-5;
			border: 1px solid transparent;
			color: $color-grey-50;
			display: flex;
			font-size: 20px;
			font-weight: 700;
			justify-content: center;
			text-decoration: none;
			transition: $motion-ease;
			transition-property: border-color;

			&:hover {
				border-color: $color-grey-50;
			}
		}
	}

	&-thumbnails_picture {
		@include g-image_container(_container, aspect-ratio(3, 4));

		img {
			@include g-image_container(_img);
		}
	}

	&-footer {
		display: flex;

		@include media(sm) {
			flex-direction: column;
		}
	}
}
