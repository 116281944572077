.b-navigation_panel {
	padding: 0;

	@include media(lg-up) {
		display: block;
		position: static;
		visibility: visible;
		z-index: initial;
	}

	&::after {
		@include media(lg-up) {
			display: none;
		}
	}

	.b-dialog-close {
		top: rh(1);

		@include media(lg-up) {
			display: none;
		}
	}

	&-inner {
		@include media(md-down) {
			@include g-section_holder;

			background-color: $color-bg-panel;
			bottom: 0;
			height: 100%;
			left: 0;
			max-width: 300px;
			overflow-y: auto;
			padding: rh(14 0 14);
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			transition-property: visibility, transform;
			visibility: hidden;
			width: 80vw;
			z-index: z(modal);

			@include rtl {
				left: initial;
				right: 0;
				transform: translateX(100%);
			}

			&.m-closed {
				transition: $motion-ease-popups;
			}

			&.m-active {
				box-shadow: $depth-3;
				transform: translateX(0);
				transition: $motion-ease-popups;
				visibility: visible;

				@include rtl {
					transform: translateX(0);
				}
			}
		}

		@include media(md) {
			padding-left: grid-margin(sm);
			padding-right: grid-margin(sm);
		}
	}

	&-title {
		@include g-heading_4;

		display: block;
		margin-bottom: rh(8);

		@include media(md-down) {
			display: none;
		}
	}
}
