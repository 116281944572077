.b-cards_grid {
	@include g-grid;

	&-body {
		display: flex;
		flex-flow: column;
	}

	&-item {
		border: 1px solid $color-divider;
		min-height: 200px;
		padding: rh(8 6);

		@include media(xl) {
			grid-column: span 5;
		}

		@include media(lg-down) {
			grid-column: span 6; // SM breakpoint use 6 column grid, so it is equal to 12/12
		}

		&.m-payment_card {
			border-radius: 3px;
		}

		&.m-add_new {
			align-items: center;
			border: 2px solid $color-primary;
			color: $color-primary;
			display: flex;
			font-size: 16px;
			justify-content: center;
			text-decoration: none;

			@include media(md-up) {
				flex-direction: column;
			}

			@include media(sm) {
				min-height: auto;
			}

			@include hover-supported {
				&:hover {
					text-shadow: 1px 0 0 $color-button;
				}
			}
		}

		&.m-default {
			background-color: $color-bg-shade;
		}

		&.m-invalid {
			border-color: $color-error;

			.b-cards_grid-body {
				color: $color-grey46;
			}
		}
	}

	&-add_new {
		font-size: 14px;
		margin-top: rh(4);
		text-transform: uppercase;

		@include media(sm) {
			margin-inline-start: rh(4);
			margin-top: 0;
		}
	}

	&-header {
		align-items: baseline;
		display: flex;
		font-weight: 500;
		justify-content: flex-end;
		margin-bottom: rh(4);

		&.m-align_top {
			align-items: flex-start;
			margin-bottom: rh(2);
		}
	}

	&-header_left {
		margin-inline-end: auto;
	}

	&-header_right {
		margin-inline-start: rh(5);
		white-space: nowrap;
	}

	&-title {
		font-size: 16px;
		font-weight: 700;
		margin-inline-end: auto;
	}

	&-link {
		@include g-link(underlined);

		display: inline-block;

		& + & {
			margin-inline-start: rh(5);
		}
	}

	&-expired {
		color: $color-error;
		text-transform: uppercase;
	}

	&-delete {}

	&-info {
		font-weight: 300;
		line-height: 1.6;
		margin-bottom: rh(4);
		word-break: break-word;
	}

	&-shipping {
		align-items: center;
		display: flex;
		font-weight: 500;
		margin-top: rh(2);
		width: 100%;

		&.m-default {
			order: -1;
		}
	}

	&-icon {
		color: $color-black;
		margin-inline-end: rh(3);
	}

	&-message {
		color: $color-success;
		font-weight: 500;
		margin-top: rh(5);
	}
}
