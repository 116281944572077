@use 'sass:color';

.b-navigation_show {
	display: flex;
	justify-content: flex-start;
	margin: 0 0 24px;
	padding: 0;
	width: 100%;

	@include media(lg-up) {
		display: none;
	}

	svg {
		background-color: $color-white;
		border-inline-end: 1px solid $color-action-obj;
		box-sizing: content-box;
		height: 100%;
		padding: 0 12px;
		transition: $motion-ease;
		transition-property: background-color, border-color;
	}

	&-text {
		margin: 0 auto;
	}
}
