@charset "UTF-8";
/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behavior of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# RTL selector

This mixin is designed to alter styles for RTL languages.

It mostly needed for alter position:absolute left|right coords, but could be used
as facade for different selectors.

## Usage

```scss
.component {
	left: 0;
	@include rtl {
		left: initial;
		right: 0;
	};
}
```
*/
/* stylelint-disable selector-no-vendor-prefix */
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_iconed

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_iconed;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link();
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

Grid layout component based on CSS grid.

It is designed to easy use project defined grid into components where CSS grid is
applicable.

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```
*/
.l-account {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 72px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .l-account {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-account {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .l-account {
    margin-bottom: 88px;
  }
}
.l-account.m-small {
  max-width: 450px;
  padding-inline-end: 15px;
  padding-inline-start: 15px;
}
.l-account.m-top_margin {
  margin-top: 40px;
}
.l-account.m-password_message {
  margin-top: 24px;
}
@media screen and (min-width: 1024px) {
  .l-account.m-password_message {
    margin-top: 48px;
  }
}
.b-account_banner + .l-account.m-password_message {
  margin-top: -12px;
}
@media screen and (min-width: 1024px) {
  .b-account_banner + .l-account.m-password_message {
    margin-top: -60px;
  }
}
.l-account.m-gift_certificate_balance {
  margin-bottom: 88px;
  margin-top: 80px;
  max-width: 480px;
  padding-inline-end: 15px;
  padding-inline-start: 15px;
}
@media screen and (max-width: 767px) {
  .l-account.m-gift_certificate_balance {
    margin-bottom: 56px;
    margin-top: 32px;
  }
}
.l-account-message {
  margin-bottom: 24px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .l-account-message {
    margin-bottom: 48px;
  }
}
.l-account-inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .l-account-inner {
    flex-wrap: nowrap;
  }
}
.l-account-nav {
  overflow: hidden;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-account-nav {
    flex-basis: 33.3333333333%;
  }
}
@media screen and (min-width: 1367px) {
  .l-account-nav {
    flex-basis: 25%;
  }
}
.l-account-main {
  flex-basis: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .l-account-main {
    flex-basis: 66.6666666667%;
    padding-inline-start: 32px;
  }
}
@media screen and (min-width: 1367px) {
  .l-account-main {
    flex-basis: 75%;
    padding-inline-start: 68px;
  }
}
.l-account-carousel {
  width: 100%;
}

/*md

# Form

Form component designed to create an easy and versatile form layout system.

Forms are built with a combination of standard form elements with a `.b-form_section` wrapper to hold labels, errors and caption for each field.\

Each line can hold from one to three form elements, it required only modifier to control that. Also it possible to use any form element inside lines

```html_example
<form class="b-form" autocomplete="on" novalidate="" data-widget="shippingForm" data-event-submit.prevent="handleSubmit" data-widget-event-updateshippingform="updateSummaryEv" data-address-selector="shippingAddressSelector" data-address-fields="dwfrm_shipping_shippingAddress_addressFields_firstName,dwfrm_shipping_shippingAddress_addressFields_lastName,dwfrm_shipping_shippingAddress_addressFields_address1,dwfrm_shipping_shippingAddress_addressFields_address2,dwfrm_shipping_shippingAddress_addressFields_country,dwfrm_shipping_shippingAddress_addressFields_states_stateCode,dwfrm_shipping_shippingAddress_addressFields_city,dwfrm_shipping_shippingAddress_addressFields_postalCode,dwfrm_shipping_shippingAddress_addressFields_phone" data-widget-event-submit="shippingSubmitted" data-select-shipping-method-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-SelectShippingMethod" data-update-shipping-list-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-UpdateShippingMethodsList" data-create-shipment-url="https://zzrk-007.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutAddressServices-CreateNewAddress" action="/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/CheckoutShippingServices-SubmitShipping" data-address-mode="edit" data-form-definition-url="/on/demandware.store/Sites-Boilerplate-NA-Site/en_US/Forms-FormDefinition?form=shipping" name="dwfrm_shipping" id="dwfrm_shipping" data-address-autocomplete-enabled="false" data-initialized="1" aria-busy="false">

	<div class="b-form-message" role="alert" data-ref="errorMessageLabel">Form error message here</div>

	<fieldset class="b-form-set" data-ref="addressForm">
		<legend class="b-form-set_label">
			Shipping address (form-set)
		</legend>

		<div class="b-form_section">
			<label class="b-form_section-label">
				First Name (form_section)
			</label>
			<input class="b-input" type="text" placeholder="First Name">
		</div>

		<div class="b-form-line m-equal">
			<div class="b-form_section">
				<label class="b-form_section-label">
					First Name (form-line equal)
				</label>
				<input class="b-input" type="text" placeholder="First Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Last Name (form-line)
				</label>
				<input class="b-input" type="text" placeholder="Last Name">
			</div>
		</div>

		<div class="b-form-line m-fixed_width">
			<div class="b-form_section">
				<label class="b-form_section-label">
					First Name (form-line fixed-width)
				</label>
				<input class="b-input" type="text" placeholder="First Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Last Name
				</label>
				<input class="b-input" type="text" placeholder="Last Name">
			</div>

			<div class="b-form_section">
				<label class="b-form_section-label">
					Phone Number
				</label>
				<input class="b-input" type="text" placeholder="Phone Number">
			</div>
		</div>
	</fieldset>
</form>
```

*/
.b-form-title {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 32px;
}
.b-form-description {
  margin-bottom: 20px;
  padding: 8px 0;
}
.b-form-message {
  background: #feeeee;
  border: 1px solid #d54a4a;
  color: #000001;
  font-weight: 300;
  margin-bottom: 32px;
  padding: 16px;
  text-align: center;
}
.b-form-error_feedback {
  color: #d54a4a;
  font-size: 12px;
  font-weight: 500;
  margin-top: 20px;
}
.b-form-set {
  border: none;
  display: block;
  margin: 16px 0;
  min-width: 0;
  padding: 0;
  position: relative;
}
.b-form-set[hidden] {
  display: none;
}
.b-form-set_label {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 16px;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}
.b-form-set_label.m-wai {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-form-line {
  display: flex;
  width: 100%;
}
.b-form-line.m-actions {
  margin-top: 48px;
}
.b-form-line[hidden] {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  left: 0;
  max-height: 1px;
  max-width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}
.b-form-line > .b-form_section {
  width: 100%;
}
.b-form-line.m-equal {
  justify-content: space-between;
}
.b-form-line.m-equal .b-form_section {
  width: calc(50% - 10px);
}
@media screen and (max-width: 767px) {
  .b-form-line.m-equal .b-form_section {
    width: 83%;
  }
}
.b-form-line.m-fixed_width .b-form_section {
  width: 150px;
}
@media screen and (max-width: 767px) {
  .b-form-line.m-mobile_column {
    flex-flow: column;
  }
}
.b-form-line.m-card_expiration {
  flex-wrap: nowrap;
}
.b-form-line_divider {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 300;
  height: 48px;
  margin: 0 16px;
}
.b-form-btn_save {
  margin-inline-end: 20px;
}
@media screen and (max-width: 767px) {
  .b-form-btn_save {
    margin: 0 0 20px;
  }
}
.b-form-btn_cancel {
  padding: 0 36px;
}
.b-form-text {
  font-size: 14px;
  margin-bottom: 10px;
}
.b-form-text.m-top_margin {
  margin-top: 40px;
}

@media screen and (min-width: 768px) {
  .b-form.m-account {
    flex-grow: 0;
    max-width: 500px;
  }
}

@media screen and (min-width: 768px) {
  .b-form.m-order_cancel {
    flex-grow: 0;
    max-width: 580px;
  }
}
.b-form.m-order_cancel .b-select {
  max-width: 375px;
}

/*md

# b-checkbox

Please see [g-checkbox](02-components-g-checkbox.html) for details.

*/
.b-checkbox {
  color: #757575;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  user-select: none;
}
.b-checkbox-input {
  cursor: pointer;
  height: 24px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 24px;
  z-index: 1;
}
html[dir=rtl] .b-checkbox-input {
  left: initial;
  right: 0;
}
.b-checkbox-icon {
  background-color: #ffffff;
  border: 1px solid #000001;
  border-radius: 0;
  cursor: pointer;
  margin-inline-end: 14px;
  min-width: 24px;
  position: relative;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: border-color;
  background: transparent;
  height: 24px;
  margin-top: 1px;
  padding: 3px;
  width: 24px;
}
.b-checkbox-icon path {
  stroke: #000001;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
  transform: scale(0);
  transform-origin: center center;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: transform;
}
.b-checkbox-input:active + .b-checkbox-icon {
  transform: scale(0.9);
}
.b-checkbox:hover .b-checkbox-icon {
  border-color: #000001;
}
.b-checkbox-input:checked + .b-checkbox-icon {
  border: 2px solid #000001;
  border: 1px solid #000001;
}
.b-checkbox-input:checked + .b-checkbox-icon path {
  transform: scale(1);
}
.b-checkbox-input[disabled] + .b-checkbox-icon {
  background-color: #f2f2f2;
  border-color: #bababa;
  cursor: default;
  transform: scale(1);
}
.b-checkbox-input[disabled] + .b-checkbox-icon path {
  stroke: #757575;
}
.b-checkbox-input.m-invalid + .b-checkbox-icon {
  border-color: #d54a4a;
}
.b-checkbox-label {
  cursor: pointer;
}
.b-checkbox-input[disabled] + .b-checkbox-icon + .b-checkbox-label {
  color: #bababa;
  cursor: default;
}

/*md

# Textarea

Default textarea element

## Default

```html_example
	<textarea
		id=""
		class="b-textarea m-valid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

## Invalid

```html_example
	<textarea
		id=""
		class="b-textarea m-invalid"
		data-ref="field"
		placeholder="Enter your text…"
		rows="5"
	></textarea>
```

*/
.b-textarea {
  appearance: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 1, 0.3);
  border-radius: 0;
  box-shadow: none;
  color: #000001;
  cursor: text;
  font-family: inherit;
  font-size: 16px;
  max-width: 100%;
  min-height: 6em;
  min-width: 100%;
  padding: 8px 16px;
  vertical-align: baseline;
}
.b-textarea.m-invalid {
  background: #feeeee;
  border: 2px solid #d54a4a;
}
.b-textarea.m-no_resize {
  resize: none;
}
.b-textarea.m-disabled, .b-textarea[disabled] {
  background: #f2f2f2;
  cursor: default;
  pointer-events: none;
}

/*md

# Input password

Designed with possibility to `show/hide` masked text into input fields

```html_example
<div class="b-input_password" data-widget="inputPassword">
	<input data-ref="field" id="" type="password" class="b-input m-valid" aria-describedby="" placeholder="" name="" required="" value="" maxlength="64" aria-required="true" minlength="8" data-event-input="onPasswordInput" data-tau="" data-event-blur="validate">
	<button class="b-input_password-toggle_visibility" data-ref="showButton" data-event-click="toggleMask" data-button-text-show="Show" data-button-text-hide="Hide" aria-pressed="false" title="Toggle password field visibility" type="button">
		Show
	</button>
</div>
```

```html_example
<div class="b-input_password" data-widget="inputPassword">
	<input data-ref="field" id="" type="password" class="b-input m-valid" aria-describedby="" placeholder="" name="" required="" value="" maxlength="64" aria-required="true" minlength="8" data-event-input="onPasswordInput" data-tau="" data-event-blur="validate">
	<button class="b-input_password-toggle_visibility" data-ref="showButton" data-event-click="toggleMask" data-button-text-show="Show" data-button-text-hide="Hide" aria-pressed="false" title="Toggle password field visibility" type="button" hidden="hidden">
		Show
	</button>
</div>
```

## Error State

```html_example
<div class="b-input_password" data-widget="inputPassword">
	<input data-ref="field" id="" type="password" class="b-input m-invalid" aria-describedby="" placeholder="" name="" required="" value="" maxlength="64" aria-required="true" minlength="8" data-event-input="onPasswordInput" data-tau="" data-event-blur="validate">
	<button class="b-input_password-toggle_visibility" data-ref="showButton" data-event-click="toggleMask" data-button-text-show="Show" data-button-text-hide="Hide" aria-pressed="false" title="Toggle password field visibility" type="button" hidden="hidden">
		Show
	</button>
</div>
```
*/
.b-input_password {
  align-items: center;
  display: flex;
  position: relative;
}
.b-input_password-toggle_visibility {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  right: 16px;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-input_password-toggle_visibility:hover {
    text-decoration: underline;
  }
}
html[dir=rtl] .b-input_password-toggle_visibility {
  left: 16px;
  right: initial;
}
.b-input_password .b-input {
  padding-inline-end: 68px;
}

.b-input_as_text {
  appearance: none;
  border: none;
  color: inherit;
  display: block;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
}

/*md

# Tabs (`b-tab_list` / `b-tab_panel`)

Tabs presents multiple mutually exclusive panes of content in the same area.
Includes a tabbed control(tab) and a content area. Clicking a tab displays its corresponding pane in the content area.

## Attributes

```
[boolean] - data-active-first - activate first tab and first tab panel
[string]  - data-active-panel - activate tab and tab panel by provided panel id
[boolean] - data-auto-activation - if tabs list should follow accessibility `Tabs with Automatic Activation` feature
```

## Usage

To get started with tabs we should link the tab and the content area.
We have a `data-panel-name` attribute on a tab and `id` attribute on the pane for that.

### Tabs with automatic activation (data-auto-activation="true")

The tab pane content will change just in case of using arrow keys.
With using just a Tab key, inactive tabs will be skipped by focus.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="true"
    data-active-panel="firstPanel"
    data-event-keydown="handleKeydown"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
                tabindex="-1"
                aria-selected="false"
                class="b-tab_list-tab"
                data-panel-name="secondPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-secondPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

### Tabs with manual activation (data-auto-activation="false")

The tab pane content will change just in case of using Enter/Space button on focused element.
Click functionality works as usual.

```html_example
<div
    data-widget="tabs"
    data-auto-activation="false"
    data-event-keydown="handleKeydown"
    data-active-panel="firstPanel"
>
    <div data-ref="tablist" role="tablist" class="b-tab_list">
        <button aria-selected="true"
                class="b-tab_list-tab"
                data-panel-name="firstPanel"
                data-widget-event-click="handleTabClick"
                data-id="button-firstPanel"
                data-event-click.prevent="handleClick"
                data-widget="button"
                role="tab"
        >
            First tab
        </button>

        <button
            aria-selected="false"
            class="b-tab_list-tab"
            data-panel-name="secondPanel"
            data-widget-event-click="handleTabClick"
            data-id="button-secondPanel"
            data-event-click.prevent="handleClick"
            data-widget="button"
            role="tab"
        >
            Second tab
        </button>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="firstPanel"
    >
        <h1>The content of a FIRST tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.</p>
    </div>

    <div
        role="tabpanel"
        tabindex="0"
        aria-expanded="false"
        class="b-tab_panel"
        aria-labelledby="content-pane-tab"
        data-widget="tabPanel"
        id="secondPanel"
    >
        <h1>The content of a SECOND tab</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua.</p>
    </div>
</div>
```

## SCSS Notes

We have two SCSS blocks for Tabs. The first is for tab controls `b-tab_list` , and the second is fortab content
areas `b-tab_panel`.
*/
.b-tab_list {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 16px auto;
  overflow-x: auto;
  scrollbar-width: none;
  user-select: none;
}
.b-tab_list::-webkit-scrollbar {
  display: none;
}
.b-tab_list.m-search {
  margin: 0 0 44px;
}
.b-tab_list.m-pdp {
  font-size: 2rem;
  justify-content: flex-start;
  margin: 0 0 32px -8px;
}
.b-tab_list.m-checkout {
  justify-content: flex-start;
}
.b-tab_list.m-account {
  margin-bottom: 36px;
}
.b-tab_list-tab {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: 400;
  padding: 15px 30px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .b-tab_list-tab {
    padding: 15px 30px;
    width: 100%;
  }
}
.b-tab_list-tab:hover {
  color: #000001;
}
.b-tab_list-tab::after {
  background-color: #d8d8d8;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}
.b-tab_list-tab.m-active {
  font-weight: 700;
}
.b-tab_list-tab.m-active::after {
  background-color: #000001;
  height: 2px;
}
.b-tab_list.m-checkout .b-tab_list-tab {
  font-weight: bold;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .b-tab_list.m-search .b-tab_list-tab {
    font-size: 20px;
    padding: 12px;
  }
}

.b-tab_panel {
  display: none;
  width: 100%;
}
.b-tab_panel.m-active {
  display: block;
}

/*md

# b-message

TBD

*/
.b-message {
  background-color: #f2f2f2;
  border: 1px solid #c5c5c5;
  border-radius: 2px;
  color: #000001;
  font-size: 12px;
  margin-bottom: 8px;
  padding: 6px 10px;
  text-align: center;
}
.b-message:last-child {
  margin: 0;
}
.b-message.m-success {
  background-color: #f2f2f2;
  color: #000001;
}
.b-message.m-error {
  background-color: #feeeee;
  border-color: #d54a4a;
  color: #000001;
}
.b-message.m-warning {
  background-color: #f2f2f2;
  border-color: #000001;
}
.b-message.m-order_confirmation {
  font-size: 16px;
  margin-top: 28px;
}
.b-message.m-info {
  display: flex;
  font-size: 14px;
  padding: 10px 20px;
  position: relative;
  text-align: left;
}
.b-message.m-disclaimer {
  margin-bottom: 20px;
}
.b-message-icon {
  border: 1px solid;
  border-radius: 50%;
  flex: 0 0 18px;
  height: 18px;
  line-height: 1;
  text-align: center;
}
.b-message-text {
  padding-left: 10px;
}
.b-message-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
}
@media not all and (pointer: coarse) {
  .b-message-link:hover {
    text-decoration: underline;
  }
}
.b-message-link.m-highlighted {
  font-weight: 500;
}

.b-social {
  align-items: center;
  display: flex;
}
.b-social-title {
  font-size: 16px;
  font-weight: 500;
  margin-inline-end: 12px;
}
@media screen and (max-width: 767px) {
  .b-social-title {
    display: none;
  }
}
.b-social-items {
  display: flex;
}
.b-social-link {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
  color: #404040;
}
.b-social-link:hover {
  color: #000001;
}

.b-summary_section {
  margin: 28px 0;
}
.b-summary_section.m-payments {
  margin-bottom: 0;
}
.b-summary_section-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.b-summary_table {
  font-weight: 500;
  width: 100%;
}
.b-summary_table-name, .b-summary_table-value {
  font-weight: inherit;
  padding: 5px 0;
  text-align: start;
}
.b-summary_table-name {
  padding-right: 14px;
}
.b-summary_table-name.m-tooltip {
  display: flex;
}
.b-summary_table-value {
  text-align: end;
  vertical-align: top;
  white-space: nowrap;
}
.b-summary_table-value.m-free_shipping {
  color: #d54a4a;
  text-transform: uppercase;
}
.b-summary_table-value.m-top_align {
  vertical-align: top;
}
.b-summary_table-item {
  display: table;
  width: 100%;
}
.b-summary_table-item.m-discount {
  color: #d54a4a;
}
.b-summary_table-item.m-total {
  border-top: 1px solid #d8d8d8;
  font-size: 16px;
  font-weight: 700;
  margin: 14px 0 0;
}
.b-summary_table-item.m-total .b-summary_table-name,
.b-summary_table-item.m-total .b-summary_table-value {
  padding: 19px 0 15px;
}
.b-summary_table-tax {
  color: #757575;
  font-size: 12px;
  font-weight: 400;
}

.b-summary_address {
  font-weight: 300;
  line-height: 1.6;
  word-break: break-word;
}
.b-summary_address-default {
  color: #757575;
  font-weight: 500;
  margin-top: 16px;
}

.b-summary_shipping {
  font-weight: 300;
  line-height: 1.6;
}
.b-summary_shipping-arrival_time {
  color: #757575;
  display: block;
}
.b-summary_shipping-cost {
  font-weight: bold;
  margin-inline-start: auto;
}

.b-summary_payment {
  align-items: center;
  display: flex;
  margin-bottom: 32px;
}
.b-summary_payment-number {
  font-size: 12px;
  font-weight: 500;
  margin-inline-start: 8px;
}
.b-summary_payment-line {
  display: block;
}

.b-summary_group-item {
  margin-top: 24px;
}
.b-summary_group-email {
  font-weight: 300;
  word-wrap: break-word;
}
.b-summary_group-title {
  font-weight: 600;
  margin-bottom: 12px;
}
.b-summary_group-caption {
  color: #757575;
}
.b-summary_group-message {
  color: #2f7f33;
  font-weight: 500;
  margin-top: 20px;
}

.b-summary_group.m-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.b-summary_group.m-columns .b-summary_group-item {
  margin-top: 0;
  width: calc(50% - 12px);
}
.b-summary_group.m-columns .b-summary_group-item.m-gift {
  margin-top: 24px;
  width: 100%;
  word-break: break-word;
}

.b-checkout_products {
  color: #000001;
}
.b-checkout_products-counter {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.b-payment_icon {
  background: url("./images/icons/payment-sprite.svg") no-repeat;
  background-size: cover;
  display: inline-block;
  flex-shrink: 0;
  height: 22px;
  width: 32px;
}
.b-payment_icon.m-payment_list {
  height: 37px;
  width: 54px;
}
.b-payment_icon.m-amex {
  background-position: 0% 0;
}
.b-payment_icon.m-discover {
  background-position: 16.6666666667% 0;
}
.b-payment_icon.m-visa {
  background-position: 33.3333333333% 0;
}
.b-payment_icon.m-mastercard, .b-payment_icon.m-master {
  background-position: 50% 0;
}
.b-payment_icon.m-diners {
  background-position: 66.6666666667% 0;
}
.b-payment_icon.m-jcb {
  background-position: 83.3333333333% 0;
}
.b-payment_icon.m-maestro {
  background-position: 100% 0;
}

.b-form_line_cvv {
  position: relative;
  width: 100%;
}
.b-form_line_cvv .b-input {
  width: calc(50% - 20px);
}
.b-form_line_cvv-image {
  align-items: center;
  display: flex;
  height: 48px;
  position: absolute;
  right: 0;
  top: 26px;
  width: 50%;
}
html[dir=rtl] .b-form_line_cvv-image {
  left: 0;
  right: initial;
}

.b-load_progress {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .b-load_progress {
    margin-top: 10px;
  }
}
.b-load_progress-description {
  font-size: 16px;
  margin-bottom: 15px;
}
.b-load_progress-indicator {
  background-color: #f2f2f2;
  height: 4px;
  max-width: 408px;
  width: 100%;
}
.b-load_progress-value {
  background-color: #cdb5a7;
  height: 100%;
}

.b-load_more {
  margin-top: 20px;
  text-align: center;
}
.b-load_more.m-previous {
  margin-top: 15px;
}
.b-load_more-button {
  font-size: 14px;
  padding: 0 40px;
}
@media screen and (max-width: 767px) {
  .b-load_more-button {
    width: 100%;
  }
}

.b-reset_password {
  position: relative;
}
.b-reset_password-btn {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
  font-size: 12px;
  line-height: 1.2;
  position: absolute;
  right: 0;
  top: 0;
}
@media not all and (pointer: coarse) {
  .b-reset_password-btn:hover {
    text-decoration: none;
  }
}
html[dir=rtl] .b-reset_password-btn {
  left: 0;
  right: initial;
}
.b-reset_password-btn::after {
  bottom: -6px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: -6px;
}

@media screen and (min-width: 1367px) {
  .b-order_details {
    display: flex;
  }
}
.b-order_details.m-cancel, .b-order_details.m-guest {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 24px auto;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-order_details.m-cancel, .b-order_details.m-guest {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-order_details.m-cancel, .b-order_details.m-guest {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-order_details-top {
  padding-bottom: 40px;
  width: 100%;
}
.b-order_details-caption {
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
  padding-bottom: 12px;
}
.b-order_details-main {
  margin-bottom: 56px;
}
@media screen and (min-width: 1367px) {
  .b-order_details-main {
    margin-bottom: 0;
    padding-inline-end: 12%;
    width: 65%;
  }
}
@media screen and (min-width: 1367px) {
  .b-order_details.m-cancel .b-order_details-main {
    padding-inline-end: 0;
    width: 100%;
  }
}
.b-order_details-asset {
  border-top: 1px solid #d8d8d8;
  margin-bottom: 40px;
  padding-top: 40px;
}
.b-order_details-footer {
  border-top: 1px solid #d8d8d8;
  display: flex;
  margin-bottom: 20px;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  .b-order_details-footer {
    flex-direction: column;
  }
}
@media screen and (min-width: 1367px) {
  .b-order_details-aside {
    width: 35%;
  }
}
.b-order_details.m-guest .b-order_details-aside {
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-order_details.m-guest .b-order_details-aside {
    width: 42%;
  }
}
@media screen and (min-width: 1024px) {
  .b-order_details.m-guest .b-order_details-aside {
    max-width: 300px;
  }
}
.b-order_details-aside.m-checkout {
  width: 100%;
}
.b-order_details-aside_item {
  margin-bottom: 48px;
}
.b-order_details-aside_item:first-child {
  margin-bottom: 28px;
}
.b-order_details-aside_item:last-child {
  margin-bottom: 0;
}
.b-order_details-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 32px;
  width: 100%;
}
.b-order_details-subtitle {
  font-weight: 600;
  margin-bottom: 12px;
}
.b-order_details-counter {
  margin-bottom: 10px;
}
.b-order_details-cancellation_text + .b-order_details-cancellation_text {
  margin-top: 10px;
}
.b-order_details-cancellation_text.m-accent {
  font-weight: 700;
}
.b-order_details-cancellation_body {
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px 0;
  padding-top: 20px;
}
.b-order_details-cancellation_footer {
  margin-top: 20px;
}

.b-navigation_show {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 24px;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .b-navigation_show {
    display: none;
  }
}
.b-navigation_show svg {
  background-color: #ffffff;
  border-inline-end: 1px solid #000001;
  box-sizing: content-box;
  height: 100%;
  padding: 0 12px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: background-color, border-color;
}
.b-navigation_show-text {
  margin: 0 auto;
}

.b-navigation_panel {
  padding: 0;
}
@media screen and (min-width: 1024px) {
  .b-navigation_panel {
    display: block;
    position: static;
    visibility: visible;
    z-index: initial;
  }
}
@media screen and (min-width: 1024px) {
  .b-navigation_panel::after {
    display: none;
  }
}
.b-navigation_panel .b-dialog-close {
  top: 4px;
}
@media screen and (min-width: 1024px) {
  .b-navigation_panel .b-dialog-close {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .b-navigation_panel-inner {
    margin: 0 auto;
    max-width: 1366px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #ffffff;
    bottom: 0;
    height: 100%;
    left: 0;
    max-width: 300px;
    overflow-y: auto;
    padding: 56px 0 56px;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition-property: visibility, transform;
    visibility: hidden;
    width: 80vw;
    z-index: 10;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) and (max-width: 1023px) {
  .b-navigation_panel-inner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 1023px) and (max-width: 767px) {
  .b-navigation_panel-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1023px) {
  html[dir=rtl] .b-navigation_panel-inner {
    left: initial;
    right: 0;
    transform: translateX(100%);
  }
}
@media screen and (max-width: 1023px) {
  .b-navigation_panel-inner.m-closed {
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
  }
}
@media screen and (max-width: 1023px) {
  .b-navigation_panel-inner.m-active {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
    transform: translateX(0);
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;
    visibility: visible;
  }
  html[dir=rtl] .b-navigation_panel-inner.m-active {
    transform: translateX(0);
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-navigation_panel-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-navigation_panel-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 32px;
}
@media screen and (max-width: 1023px) {
  .b-navigation_panel-title {
    display: none;
  }
}

.b-customer_service_info-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}
.b-customer_service_info-subtitle {
  font-size: 16px;
  margin-bottom: 12px;
}
.b-customer_service_info-list {
  font-weight: 300;
  margin-bottom: 16px;
}
.b-customer_service_info-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-customer_service_info-link:hover {
    text-decoration: underline;
  }
}

.b-login {
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  max-width: 900px;
}
@media screen and (max-width: 767px) {
  .b-login {
    flex-direction: column;
  }
}
.b-login-section {
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .b-login-section {
    flex: 1 0 50%;
  }
}
@media screen and (min-width: 768px) {
  .b-login-section:first-child {
    border-right: 1px solid #f2f2f2;
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) {
  .b-login-section + .b-login-section {
    padding-left: 40px;
  }
}
.b-login-title {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
}

.b-track_order {
  padding: 40px 24px;
}
.b-track_order-inner {
  margin: 0 auto;
  max-width: 450px;
}

.b-track_notification {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 48px auto 0;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-track_notification {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-track_notification {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-track_notification-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}
.b-track_notification-image {
  color: #000001;
  margin-bottom: 8px;
  position: relative;
}
.b-track_notification-image::before {
  background-color: #f2f2f2;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}
.b-track_notification-image svg {
  background-color: #ffffff;
  box-sizing: content-box;
  padding: 0 16px;
  position: relative;
}
.b-track_notification-message {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 28px;
}
.b-track_notification-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-track_notification-link:hover {
    text-decoration: none;
  }
}

.b-gift_certificate_balance-header {
  font-size: 27px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.b-gift_certificate_balance-message {
  margin-bottom: 24px;
}
.b-gift_certificate_balance-value {
  font-weight: 700;
}

.b-card {
  border-bottom: 2px solid #c5c5c5;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: hidden;
  padding-bottom: 40px;
}
.b-card:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.b-card.m-account {
  border-bottom: 0;
  margin-bottom: 16px;
  padding-bottom: 0;
}
.b-card-inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .b-card-inner {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .b-card-inner_item {
    width: calc(50% - 20px);
  }
}
.b-card-header {
  margin: 20px 0 8px;
}
.b-card-title {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: start;
}
.b-card-shipment {
  margin: 20px 0 10px;
}
.b-card-shipment.m-large {
  font-size: 20px;
}
.b-card-shipment_text {
  font-weight: 500;
}
.b-card-body {
  padding: 8px 0 24px;
}
.b-card-body ~ .b-card-body {
  border-top: 1px dashed #c5c5c5;
  margin-top: 20px;
}
.b-card-body table {
  width: 100%;
}
.b-card-body th,
.b-card-body td {
  text-align: start;
}
@media screen and (max-width: 767px) {
  .b-card-body th,
.b-card-body td {
    display: table-row;
  }
}
@media screen and (max-width: 767px) {
  .b-card-body tr {
    display: table-row-group;
  }
}
.b-card-body th {
  padding-bottom: 4px;
  width: 125px;
}
.b-card-button {
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .b-card-button {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media screen and (min-width: 768px) {
  .b-card-button {
    margin-bottom: 0;
    margin-inline-end: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-card-button:last-child {
  margin-bottom: 0;
  margin-inline-end: 0;
}
.b-card-list {
  font-weight: 300;
  line-height: 1.7;
}
.b-card-list_link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-card-list_link:hover {
    text-decoration: none;
  }
}
.b-card-message {
  font-weight: 300;
  margin-bottom: 4px;
}
.b-card-thumbnails {
  display: flex;
  margin-top: 20px;
}
.b-card-thumbnails_item {
  margin-inline-end: 8px;
  max-width: 100%;
  width: 87px;
}
@media screen and (min-width: 768px) {
  .b-card-thumbnails_item {
    margin-inline-end: 20px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-card-thumbnails_item {
    max-width: 15.4%;
  }
}
@media screen and (max-width: 767px) {
  .b-card-thumbnails_item {
    max-width: 51px;
    width: 16.67%;
  }
}
.b-card-thumbnails_item:last-child {
  margin-inline-end: 0;
}
.b-card-thumbnails_item.m-additional {
  align-items: center;
  background-color: #f2f2f2;
  border: 1px solid transparent;
  color: #757575;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  text-decoration: none;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: border-color;
}
.b-card-thumbnails_item.m-additional:hover {
  border-color: #757575;
}
.b-card-thumbnails_picture {
  background: #f8f5f5;
  display: block;
  overflow: hidden;
  padding-bottom: 133.3333333333%;
  position: relative;
  width: 100%;
}
.b-card-thumbnails_picture img {
  bottom: 0;
  color: #f8f5f5;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.b-card-footer {
  display: flex;
}
@media screen and (max-width: 767px) {
  .b-card-footer {
    flex-direction: column;
  }
}

.b-account {
  color: #000001;
}
.b-account-title {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 36px;
}
.b-account-title.m-top_margin {
  margin-top: 24px;
}
.b-account-empty_state {
  margin: 16px 0 40px;
}
.b-account-messages {
  margin-bottom: 32px;
}
.b-account #adyenModalDialog {
  background-color: #ffffff;
}

.b-account_banner {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  color: #000001;
  margin: 24px auto 24px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-account_banner {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-account_banner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .b-account_banner {
    margin: 48px auto 72px;
  }
}
.b-account_banner-inner {
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  padding-bottom: 32px;
  padding-top: 32px;
  text-align: center;
}
.b-account_banner-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-account_banner-link:hover {
    text-decoration: underline;
  }
}

.b-account_benefits {
  margin-bottom: 32px;
}
.b-account_benefits-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}
.b-account_benefits-list {
  font-size: 16px;
  font-weight: 300;
  list-style: disc inside;
}
.b-account_benefits-item {
  margin-bottom: 12px;
}

.b-account_download_data {
  border-top: 1px solid #f2f2f2;
  margin-top: 48px;
  padding-top: 24px;
}
.b-account_download_data-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  align-items: center;
  display: inline-flex;
  font-weight: 700;
  text-transform: uppercase;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-account_download_data-link:hover {
    text-decoration: underline;
  }
}
.b-account_download_data-text {
  margin-inline-start: 16px;
}

.b-cards_grid {
  display: grid;
}
@media screen and (min-width: 1367px) {
  .b-cards_grid {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .b-cards_grid {
    grid-gap: 20px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-cards_grid {
    grid-gap: 16px;
    grid-template-columns: [grid-start] repeat(12, 1fr) [grid-end];
  }
}
@media screen and (max-width: 767px) {
  .b-cards_grid {
    grid-gap: 9px;
    grid-template-columns: [grid-start] repeat(6, 1fr) [grid-end];
  }
}
.b-cards_grid-body {
  display: flex;
  flex-flow: column;
}
.b-cards_grid-item {
  border: 1px solid #f2f2f2;
  min-height: 200px;
  padding: 32px 24px;
}
@media screen and (min-width: 1367px) {
  .b-cards_grid-item {
    grid-column: span 5;
  }
}
@media screen and (max-width: 1366px) {
  .b-cards_grid-item {
    grid-column: span 6;
  }
}
.b-cards_grid-item.m-payment_card {
  border-radius: 3px;
}
.b-cards_grid-item.m-add_new {
  align-items: center;
  border: 2px solid #000001;
  color: #000001;
  display: flex;
  font-size: 16px;
  justify-content: center;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .b-cards_grid-item.m-add_new {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .b-cards_grid-item.m-add_new {
    min-height: auto;
  }
}
@media not all and (pointer: coarse) {
  .b-cards_grid-item.m-add_new:hover {
    text-shadow: 1px 0 0 #000001;
  }
}
.b-cards_grid-item.m-default {
  background-color: #f2f2f2;
}
.b-cards_grid-item.m-invalid {
  border-color: #d54a4a;
}
.b-cards_grid-item.m-invalid .b-cards_grid-body {
  color: #757575;
}
.b-cards_grid-add_new {
  font-size: 14px;
  margin-top: 16px;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .b-cards_grid-add_new {
    margin-inline-start: 16px;
    margin-top: 0;
  }
}
.b-cards_grid-header {
  align-items: baseline;
  display: flex;
  font-weight: 500;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.b-cards_grid-header.m-align_top {
  align-items: flex-start;
  margin-bottom: 8px;
}
.b-cards_grid-header_left {
  margin-inline-end: auto;
}
.b-cards_grid-header_right {
  margin-inline-start: 20px;
  white-space: nowrap;
}
.b-cards_grid-title {
  font-size: 16px;
  font-weight: 700;
  margin-inline-end: auto;
}
.b-cards_grid-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
  display: inline-block;
}
@media not all and (pointer: coarse) {
  .b-cards_grid-link:hover {
    text-decoration: none;
  }
}
.b-cards_grid-link + .b-cards_grid-link {
  margin-inline-start: 20px;
}
.b-cards_grid-expired {
  color: #d54a4a;
  text-transform: uppercase;
}
.b-cards_grid-info {
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 16px;
  word-break: break-word;
}
.b-cards_grid-shipping {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-top: 8px;
  width: 100%;
}
.b-cards_grid-shipping.m-default {
  order: -1;
}
.b-cards_grid-icon {
  color: #000001;
  margin-inline-end: 12px;
}
.b-cards_grid-message {
  color: #2f7f33;
  font-weight: 500;
  margin-top: 20px;
}

.b-history_page-title {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 12px;
}
.b-history_page-track_order {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
@media not all and (pointer: coarse) {
  .b-history_page-track_order:hover {
    text-decoration: underline;
  }
}
.b-history_page-no_orders {
  margin-top: 40px;
}
.b-history_page-start_shopping {
  margin-top: 28px;
}
.b-history_page-find_order {
  margin-bottom: 32px;
}
.b-history_page-find_order_link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;
  font-weight: 500;
}
@media not all and (pointer: coarse) {
  .b-history_page-find_order_link:hover {
    text-decoration: none;
  }
}
.b-history_page-filter {
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: flex-end;
  line-height: 1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .b-history_page-filter {
    align-items: flex-end;
  }
}
.b-history_page-total {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
}
.b-history_page-select_wrap {
  align-items: center;
  display: flex;
}
.b-history_page-select_label {
  color: #757575;
  font-size: 16px;
  margin-inline-end: 12px;
}
.b-history_page-select {
  max-width: 200px;
}
@media screen and (max-width: 767px) {
  .b-history_page-select {
    max-width: 160px;
  }
}

.b-account_nav {
  margin-bottom: 44px;
}
.b-account_nav-item {
  border-top: 1px solid #f2f2f2;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  transition: color cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
}
.b-account_nav-item:last-child {
  border-bottom: 1px solid #f2f2f2;
}
.b-account_nav-item:hover {
  color: #000001;
}
.b-account_nav-item.m-active {
  color: #000001;
  font-weight: 700;
}
.b-account_nav-item_link {
  display: block;
  height: 100%;
  padding: 12px 16px;
  text-align: start;
  text-decoration: none;
}
@media not all and (pointer: coarse) {
  .b-account_nav-item_link:hover {
    text-decoration: underline;
  }
}

.b-user_greeting {
  align-items: center;
  display: flex;
  margin-bottom: 28px;
}
.b-user_greeting-icon {
  align-items: flex-end;
  border: 2px solid #000001;
  border-radius: 100%;
  margin-inline-end: 20px;
  padding: 5px;
}
.b-user_greeting-content {
  overflow: hidden;
}
.b-user_greeting-message {
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.b-payment_disclaimer {
  background-color: #f2f2f2;
  border: 1px solid #c5c5c5;
  border-radius: 2px;
  color: #000001;
  display: flex;
  margin-bottom: 20px;
  padding: 6px 10px;
}
.b-payment_disclaimer-icon {
  border: 1px solid;
  border-radius: 50%;
  flex: 0 0 18px;
  height: 18px;
  line-height: 1;
  text-align: center;
}
.b-payment_disclaimer-text {
  padding-left: 4px;
}

body {
  overflow: auto;
  overflow-y: scroll;
  pointer-events: all;
  visibility: var(--page_visibility, hidden);
}