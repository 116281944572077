.b-account {
	color: adjust-color-to-bg($color-bg);

	&-title {
		@include g-heading_1;

		margin: 0 0 36px;

		&.m-top_margin {
			margin-top: 24px;
		}
	}

	&-empty_state {
		margin: 16px 0 40px;
	}

	&-messages {
		margin-bottom: 32px;
	}

	#adyenModalDialog {
		background-color: #ffffff;
	}
}
