.b-account_download_data {
	border-top: 1px solid $color-divider;
	margin-top: rh(12);
	padding-top: rh(6);

	&-link {
		@include g-link;

		align-items: center;
		display: inline-flex;
		font-weight: 700;
		text-transform: uppercase;
		transition: color $motion-fast;
	}

	&-text {
		margin-inline-start: rh(4);
	}
}
