.b-reset_password {
	position: relative;

	&-btn {
		@include g-link(underlined);

		font-size: 12px;
		line-height: 1.2;
		position: absolute;
		right: 0;
		top: 0;

		@include rtl {
			left: 0;
			right: initial;
		}

		&::after { // to increase the clickable area
			bottom: -6px;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: -6px;
		}
	}
}
