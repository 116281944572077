.b-track_notification {
	@include g-section_holder;

	margin: rh(12) auto 0;
	text-align: center;

	&-title {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: rh(3);
	}

	&-image {
		color: $color-action;
		margin-bottom: rh(2);
		position: relative;

		&::before {
			background-color: $color-divider;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			right: 0;
			top: 50%;
		}

		svg {
			background-color: $color-bg;
			box-sizing: content-box;
			padding: 0 rh(4);
			position: relative;
		}
	}

	&-message {
		border-bottom: 1px solid $color-divider;
		padding-bottom: rh(7);
	}

	&-link {
		@include g-link(underlined);
	}
}
