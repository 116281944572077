.b-form_line_cvv {
	position: relative;
	width: 100%;

	.b-input {
		width: calc(50% - #{rh(5)});
	}

	&-image {
		align-items: center;
		display: flex;
		height: $size-input-height;
		position: absolute;
		right: 0;
		top: 26px;
		width: 50%;

		@include rtl {
			left: 0;
			right: initial;
		}
	}
}
