.b-account_nav {
	margin-bottom: 44px;

	&-item {
		border-top: 1px solid $color-divider;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		padding: 0;
		transition: color $motion-fast;

		&:last-child {
			border-bottom: 1px solid $color-divider;
		}

		&:hover {
			color: $color-action;
		}

		&.m-active {
			color: $color-action;
			font-weight: 700;
		}
	}

	&-item_link {
		display: block;
		height: 100%;
		padding: 12px 16px;
		text-align: start;
		text-decoration: none;

		@include hover-supported {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
