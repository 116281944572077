.b-account_benefits {
	margin-bottom: rh(8);

	&-title {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: rh(3);
	}

	&-list {
		font-size: 16px;
		font-weight: 300;
		list-style: disc inside;
	}

	&-item {
		margin-bottom: rh(3);
	}
}
