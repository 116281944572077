@use 'sass:math';

.b-payment_icon {
	$icon-width: 32px;
	$icons-total-width: 6 * $icon-width;

	@function geticonposition($number) {
		@return percentage(math.div($icon-width * $number, $icons-total-width));
	}

	background: url('./images/icons/payment-sprite.svg') no-repeat;
	background-size: cover;
	display: inline-block;
	flex-shrink: 0;
	height: 22px;
	width: 32px;

	// Size
	&.m-payment_list {
		height: 37px;
		width: 54px;
	}

	// Types
	&.m-amex {
		background-position: geticonposition(0)  0;
	}

	&.m-discover {
		background-position: geticonposition(1) 0;
	}

	&.m-visa {
		background-position: geticonposition(2) 0;
	}

	&.m-mastercard,
	&.m-master {
		background-position: geticonposition(3) 0;
	}

	&.m-diners {
		background-position: geticonposition(4) 0;
	}

	&.m-jcb {
		background-position: geticonposition(5) 0;
	}

	&.m-maestro {
		background-position: geticonposition(6) 0;
	}
}
