.b-summary_section {
	margin: rh(7 0);

	&.m-payments {
		margin-bottom: 0;
	}

	&-title {
		@include g-heading_4;

		margin-bottom: rh(4);
	}
}
