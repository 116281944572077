.b-customer_service_info {
	&-title {
		@include g-heading_4;

		font-weight: 700;
		margin-bottom: rh(5);
	}

	&-subtitle {
		font-size: 16px;
		margin-bottom: rh(3);
	}

	&-list {
		font-weight: 300;
		margin-bottom: rh(4);
	}

	&-link {
		@include g-link;

		font-weight: 500;
		transition: color $motion-fast;
	}
}
