.b-summary_payment {
	align-items: center;
	display: flex;
	margin-bottom: rh(8);

	&-number {
		font-size: 12px;
		font-weight: 500;
		margin-inline-start: rh(2);
	}

	&-line {
		display: block;
	}
}
